import Swiper, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'

/*
 *  Alpine.js
 */
window.Alpine = Alpine
Alpine.plugin(focus)
Alpine.start()

/*
 *  Contact Form
 */
let successText = document.querySelector(".formSuccess");
let failureText = document.querySelector(".formFailure");
let submitBtn = document.querySelector("#submitBtn");

function formSuccess(){
    successText.style.display = "initial";
    plausible('Contact', {props: {method: 'Form Submission', 'location':'Contact Form'}});
    submitBtn.setAttribute("disabled", true);
}
function formFail(error){
    console.log(error);
    failureText.style.display = "initial";
    submitBtn.setAttribute("disabled", true);
}

const handleSubmit = (e) => {
    successText.style.display = "none";
    failureText.style.display = "none";

    e.preventDefault()
    let myForm = document.getElementById('contactform');
    let formData = new FormData(myForm)
    fetch('/', {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString()
    }).then(() => formSuccess()).catch((error) =>
        formFail(error))
}
document.querySelector("form").addEventListener("submit", handleSubmit);

let navBox = document.getElementById("nav-content");
let navOverlay = document.getElementById("modaloverlay");

/* toggle mobile nav */
document.getElementById('nav-toggle').onclick = function(){
    navBox.classList.toggle("hidden");
    navOverlay.classList.toggle("hidden");
    [].forEach.call(document.getElementsByClassName("toggleIcon"), function (el) {el.classList.toggle("hidden")});
}

/* hide mobile nav when clicked outside */
document.getElementById('modaloverlay').onclick = function(){
    navBox.classList.toggle("hidden");
    navOverlay.classList.toggle("hidden");
    [].forEach.call(document.getElementsByClassName("toggleIcon"), function (el) {el.classList.toggle("hidden")});
}

/* hide mobile nav on link click */
document.addEventListener("click", function(event) {
    if (event.target.closest("#nav-content a")){
        navBox.classList.toggle("hidden");
        navOverlay.classList.toggle("hidden");
        [].forEach.call(document.getElementsByClassName("toggleIcon"), function (el) {el.classList.toggle("hidden")});
    };
    /*if (event.target.closest("#headerNav")) return;
        if ( ! navBox.classList.contains("hidden") ){
        navBox.classList.toggle("hidden");
        [].forEach.call(document.getElementsByClassName("toggleIcon"), function (el) {el.classList.toggle("hidden")});
    }*/
});

// Handle link events - those that have data-analytics
let elements = document.querySelectorAll("a[data-analytics]");
registerAnalyticsEvents(elements, handleLinkEvent);

// Handle button form events - those that have data-analytics
elements = document.querySelectorAll("button[data-analytics]");
registerAnalyticsEvents(elements, handleFormEvent);
/**
* Iterate Elements and add event listener
*
* @param {NodeList} Array of elements
* @param {string} callback function name
*/
function registerAnalyticsEvents(elements, callback) {
    for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', callback);
        elements[i].addEventListener('auxclick', callback);
    }
}
/**
* Handle Link Events with plausible
* https://github.com/plausible/analytics/blob/e1bb4368460ebb3a0bb86151b143176797b686cc/tracker/src/plausible.js#L74
*
* @param {Event} click
*/
function handleLinkEvent(event) {
    var link = event.target;
    var middle = event.type == "auxclick" && event.which == 2;
    var click = event.type == "click";
    while (link && (typeof link.tagName == 'undefined' || link.tagName.toLowerCase() != 'a' || !link.href)) {
        link = link.parentNode;
    }

    if (middle || click)
        registerEvent(link.getAttribute('data-analytics'));

        // Delay navigation so that Plausible is notified of the click
    if (!link.target) {
        if (!(event.ctrlKey || event.metaKey || event.shiftKey) && click) {
            setTimeout(function () {
            location.href = link.href;
        }, 150);
            event.preventDefault();
        }
    }
}
/**
* Handle form button submit events with plausible
*
* @param {Event} click
*/
function handleFormEvent(event) {
    event.preventDefault();

    registerEvent(event.target.getAttribute('data-analytics'));

    setTimeout(function () {
        event.target.form.submit();
    }, 150);
}
/**
* Parse data and call plausible
* Using data attribute in html eg. data-analytics='"Register", {"props":{"plan":"Starter"}}'
*
* @param {sting} data - plausible event "Register", {"props":{"plan":"Starter"}}
*/
function registerEvent(data) {
    // break into array
    let attributes = data.split(/,(.+)/);

    // Parse it to object
    let events = [JSON.parse(attributes[0]), JSON.parse(attributes[1] || '{}')];

    plausible(...events);
}

/* Testimonial Carousel */
const swiper = new Swiper('.swiper-quotes', {
    modules: [Navigation, Pagination, A11y, Autoplay],

    loop: true,
    spaceBetween: 75,
    slidesPerView: 1,
    initialSlide: 0,
    speed: 500,
    autoplay: {
        delay: 5000,
    },

    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});